import MainLayout from "../../layouts/mainLayout/MainLayout";
import PlacesListing from "../../components/Places/PlacesListing";

interface IHomePlacesProps {
  random: number;
}

const Homeplaces = (props: IHomePlacesProps) => {
  const { random } = props;

  return (
    <MainLayout>
      <PlacesListing key={random.toString()} />
    </MainLayout>
  );
};

export default Homeplaces;
