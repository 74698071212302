import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import {
  deletePlace,
  getPlace,
  getPlacePicture,
  getPlaces,
  getPlacesHistory,
  patchPlace,
  postPlace,
} from "../services/PlacesService";
import { TFilter, TJoin } from "types/query.type";
import { IPlace } from "types/place.type";
import { AxiosError, AxiosResponse } from "axios";
import { IWarehouse } from "src/types/warehouse.type";

interface IUsePlacesProps {
  join?: TJoin;
  filter?: TFilter;
}

export const usePlaces = (props: IUsePlacesProps) => {
  const { join = "", filter = "" } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["places"],
    queryFn: async () => getPlaces(join, filter),
  });

  return {
    places: data?.data?.data || [],
    ...rest,
  };
};

interface IUsePlacesLinkedToWarehouse {
  warehouseId: IWarehouse["id"];
}

export const usePlacesLinkedToWarehouse = (
  props: IUsePlacesLinkedToWarehouse,
) => {
  const { warehouseId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["places-linked-to-warehouse", { warehouseId }],
    queryFn: async () =>
      getPlaces("warehouse", `warehouse.id||eq||${warehouseId}`),
  });

  return {
    places: data?.data?.data || [],
    ...rest,
  };
};

type TUseCreatePlaceConfig = UseMutationOptions<
  AxiosResponse<IPlace>,
  AxiosError,
  { place: FormData }
>;

export const useCreatePlace = (config: TUseCreatePlaceConfig) =>
  useMutation({
    mutationFn: ({ place }) => postPlace(place),
    ...config,
  });

type TUseEditPlaceConfig = UseMutationOptions<
  AxiosResponse<IPlace>,
  AxiosError,
  { placeId: IPlace["id"]; place: FormData }
>;

export const useEditPlace = (config: TUseEditPlaceConfig) =>
  useMutation({
    mutationFn: ({ placeId, place }) => patchPlace(placeId, place),
    ...config,
  });

interface IUsePlaceProps {
  placeId: IPlace["id"];
}

export const usePlace = (props: IUsePlaceProps) => {
  const { placeId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["place", { placeId }],
    queryFn: async () => getPlace(placeId),
  });

  return { place: data?.data || {}, ...rest };
};

interface IUsePlacePictureProps {
  placeId: IPlace["id"];
}

export const usePlacePicture = (props: IUsePlacePictureProps) => {
  const { placeId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["place-picture", { placeId }],
    queryFn: async () => getPlacePicture(placeId),
  });

  return { placePicture: data?.data || null, ...rest };
};

type TUseDeletePlaceConfig = UseMutationOptions<
  AxiosResponse<void>,
  AxiosError,
  { placeId: IPlace["id"] }
>;

export const useDeletePlace = (config: TUseDeletePlaceConfig) =>
  useMutation({
    mutationFn: ({ placeId }) => deletePlace(placeId),
    ...config,
  });

interface IUsePlacesHistoryProps {
  placeId: IPlace["id"];
}

export const usePlacesHistory = (props: IUsePlacesHistoryProps) => {
  const { placeId } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["places-history", { placeId }],
    queryFn: async () => getPlacesHistory(placeId),
  });

  return {
    placesHistory: data?.data?.data || [],
    ...rest,
  };
};
