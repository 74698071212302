import { IStockHistory } from "types/stock.type";

const formatActionType = (actionType: IStockHistory["actionType"]): string => {
  switch (actionType) {
    case "ADD":
      return "Ajout";
    case "USE":
      return "Utilisation";
    case "OPEN":
      return "Ouverture";
    case "CLOSE":
      return "Fermeture";
    default:
      return actionType;
  }
};

export { formatActionType };
