import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { makeStyles } from "tss-react/mui";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinkUi from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
// @ts-ignore
import Pluralize from "react-pluralize";
import PageLoader from "../Loaders/PageLoader";
import WarehouseImage from "../Warehouses/WarehouseImage";
import PlaceImage from "../Places/PlaceImage";
import ConfirmationModalWithFormik from "../Modals/ConfirmationModalWithFormik";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import StockHistoryModal from "../StockHistory/StockHistoryModal";
import {
  useAddStockToAProduct,
  useStocksQuantityForProduct,
  useUseStockForProductAndPlace,
} from "../../Hooks/stock.hook";
import { Theme } from "@mui/material";
import { IProduct } from "types/product.type";
import { IWarehouse } from "types/warehouse.type";
import { IPlace } from "types/place.type";
import { IStockQuantity } from "types/stock.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%",
  },
  noLinkeds: {
    margin: "auto",
    textAlign: "center",
  },
  displayPlacePaperContainer: {
    minHeight: "100%",
    borderRadius: 0,
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  warehouseImageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 150,
    maxWidth: 150,
    maxHeight: 150,
    margin: "auto",
  },
  warehouseImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  warehouseTitle: {
    fontSize: "18px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
    margin: "auto",
  },
  warehouseTitleContainer: {
    display: "flex",
  },
  placesOnWarehouseContainer: {
    wordBreak: "break-word",
    textAlign: "right",
    margin: "auto",
    width: "auto",
  },
  placeImageWrapper: {
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  placeImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  placeTitle: {
    fontSize: "18px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
  },
  placeFooterContainer: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
  },
  linkGoToWarehouseOrPlaceDetail: {
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  displayPlaceContainer: {
    margin: theme.spacing(2, 0),
  },
  badgeRoot: {
    display: "inherit",
    cursor: "pointer",
  },
  paperPlace: {
    border: "1px solid",
    borderColor: theme.palette.primary.dark,
  },
}));

const Title = () => {
  return (
    <Typography component="h1" variant="h5">
      Lieux de stockage
    </Typography>
  );
};

interface IProductPlacesProps {
  productId: IProduct["id"];
  productHasOpeningDate: boolean;
  loading?: boolean;
  warehouses: Array<IWarehouse>;
  places: Array<IPlace>;
  callbackAddUseStock: () => void;
}

const ProductPlaces = (props: IProductPlacesProps) => {
  const {
    productId,
    productHasOpeningDate = null,
    loading = false,
    warehouses,
    places = [],
    callbackAddUseStock,
  } = props;

  type TDisplayPlaces = Array<{
    id: IWarehouse["id"];
    name: IWarehouse["name"];
    places: Array<IPlace>;
    warehouse?: Array<IWarehouse>;
  }>;

  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();

  const [displayAddStockModal, setDisplayAddStockModal] = useState<IPlace>();
  const [displayUseStockModal, setDisplayUseStockModal] = useState<IPlace>();
  const [isOpenModalChooseUseDate, setIsOpenModalChooseUseDate] =
    useState(false);
  const [isOpenStockQuantityModal, setIsOpenStockQuantityModal] =
    useState(false);
  const [placeForStockQuantityModal, setPlaceForStockQuantityModal] = useState<
    IPlace | undefined
  >();
  const [warehouseForStockQuantityModal, setWarehouseForStockQuantityModal] =
    useState<IWarehouse | undefined>();
  const [displayPlaces, setDisplayPlaces] = useState<TDisplayPlaces>([]);

  const {
    stocksQuantityForProduct,
    isFetching: isFetchingStocksQuantityForProduct,
    isError: isErrorStocksQuantityForProduct,
    refetch: refetchStocksQuantityForProduct,
  } = useStocksQuantityForProduct({
    productId,
  });

  useEffect(() => {
    if (isErrorStocksQuantityForProduct) {
      enqueueSnackbar(
        `Une erreur est survenue lors de la récupération de la quantité des stocks.`,
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [enqueueSnackbar, isErrorStocksQuantityForProduct]);

  useEffect(() => {
    if (!loading) {
      const newWarehousesToProduct: Array<IWarehouse> = warehouses.filter(
        (warehouse) =>
          places.find((linked) => linked.warehouseId === warehouse.id),
      );

      const newDisplayPlaces: TDisplayPlaces = [];
      newWarehousesToProduct.forEach((newWarehouseToProduct) => {
        const placesOnWarehouse = places.filter(
          (place) => place.warehouseId === newWarehouseToProduct.id,
        );
        newDisplayPlaces.push({
          ...newWarehouseToProduct,
          places: placesOnWarehouse,
        });
      });

      setDisplayPlaces(newDisplayPlaces);
    }
  }, [loading, warehouses, places]);

  const getPlaceQuantity = (quantityPlaceId: IStockQuantity["quantity"]) => {
    if (!isFetchingStocksQuantityForProduct && stocksQuantityForProduct) {
      const quantities: IStockQuantity = stocksQuantityForProduct.find(
        (stock: IStockQuantity) => stock.placeId === quantityPlaceId,
      );
      if (quantities) {
        return parseInt(quantities.quantity, 10);
      }
      return 0;
    }
    return <CircularProgress color="inherit" size={15} />;
  };

  const getWarehouseQuantity = (warehouseId: IWarehouse["id"]) => {
    const placesOnWarehouse: Array<IPlace> = [];
    warehouses.forEach((warehouse) => {
      if (warehouse.id === warehouseId && warehouse.places) {
        placesOnWarehouse.push(...warehouse.places);
      }
    });

    if (!isFetchingStocksQuantityForProduct && stocksQuantityForProduct) {
      let quantity = 0;
      placesOnWarehouse.forEach((placeOnWarehouse) => {
        quantity += getPlaceQuantity(placeOnWarehouse.id) as number;
      });
      return quantity;
    }
    return <CircularProgress color="inherit" size={15} />;
  };

  const getInitialDate = () => {
    const initialDate = moment().toDate();
    initialDate.setSeconds(0);
    initialDate.setMilliseconds(0);
    return moment(initialDate);
  };

  const getAddStockInitialValues = () => ({
    actionDate: getInitialDate(),
    actionQuantity: 1,
  });

  const { mutate: addStockToAProduct } = useAddStockToAProduct();

  const { mutate: _useStockForProductAndPlace } =
    useUseStockForProductAndPlace();

  const addStockFormik = useFormik({
    initialValues: getAddStockInitialValues(),
    onSubmit: ({ actionDate, actionQuantity }, { setSubmitting }) => {
      addStockToAProduct(
        {
          productId,
          placeId: displayAddStockModal?.id || "",
          actionDate: actionDate.toISOString(),
          actionQuantity,
        },
        {
          onSuccess: () => {
            setDisplayAddStockModal(undefined);
            refetchStocksQuantityForProduct();
            enqueueSnackbar(
              `Ajout de ${actionQuantity} produit(s) au lieu de stockage "${displayAddStockModal?.name}".`,
              {
                variant: "success",
                action: (snackbarKey) => (
                  <SnackbarCloseIcon snackbarKey={snackbarKey} />
                ),
              },
            );
          },
          onError: () => {
            enqueueSnackbar(
              `Erreur lors de l'ajout de ${actionQuantity} produit(s) au lieu de stockage "${displayAddStockModal?.name}".`,
              {
                variant: "error",
                action: (snackbarKey) => (
                  <SnackbarCloseIcon snackbarKey={snackbarKey} />
                ),
              },
            );
            setSubmitting(false);
          },
        },
      );
    },
    validationSchema: yup.object().shape({
      actionQuantity: yup
        .number()
        .required("Le nombre est requis.")
        .positive("Le nombre doit être supérieur à 0.")
        .integer("Le nombre doit être un entier."),
    }),
  });

  const getUseStockInitialValues = ({
    warehouseId,
  }: {
    warehouseId?: IWarehouse["id"];
  }) => ({
    actionDate: getInitialDate(),
    actionQuantity: 1,
    warehouseId: warehouseId || null,
  });

  const useStockFormik = useFormik({
    initialValues: getUseStockInitialValues({}),
    onSubmit: ({ actionDate, actionQuantity }, { setSubmitting }) => {
      const newActionDate = moment(actionDate);
      const params = {
        actionDate: newActionDate,
        actionQuantity,
      };

      if (productHasOpeningDate) {
        newActionDate.startOf("day");
      }

      _useStockForProductAndPlace(
        {
          productId,
          placeId: displayUseStockModal?.id || "",
          params,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(
              `Utilisation de ${actionQuantity} produit(s) au lieu de stockage "${displayUseStockModal?.name}".`,
              {
                variant: "success",
                action: (snackbarKey) => (
                  <SnackbarCloseIcon snackbarKey={snackbarKey} />
                ),
              },
            );
            setDisplayUseStockModal(undefined);
            refetchStocksQuantityForProduct();
            callbackAddUseStock();
          },
          onError: () => {
            enqueueSnackbar(
              `Erreur lors de l'utilisation de ${actionQuantity} produit(s) au lieu de stockage "${displayUseStockModal?.name}".`,
              {
                variant: "error",
                action: (snackbarKey) => (
                  <SnackbarCloseIcon snackbarKey={snackbarKey} />
                ),
              },
            );
            setSubmitting(false);
          },
        },
      );
    },
    validationSchema: yup.object().shape({
      actionQuantity: yup
        .number()
        .required("La quantité est requise.")
        .positive("La quantité doit être supérieure à 0.")
        .integer("La quantité doit être un nombre.")
        .max(
          parseInt(getPlaceQuantity(displayUseStockModal?.id || "").toString()),
          `La quantité doit être au maximum de ${getPlaceQuantity(
            displayUseStockModal?.id || "",
          ).toString()}.`,
        ),
    }),
  });

  if (!loading && places.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Title />
        <div className={classes.noLinkeds}>
          <div>Aucun lieu de stockage lié au produit.</div>
        </div>
      </Paper>
    );
  }

  const handleCloseStockHistoryModal = () => {
    setIsOpenStockQuantityModal(false);
    setPlaceForStockQuantityModal(undefined);
    setWarehouseForStockQuantityModal(undefined);
  };

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <StockHistoryModal
            open={isOpenStockQuantityModal}
            productId={productId}
            place={placeForStockQuantityModal}
            warehouse={warehouseForStockQuantityModal}
            handleClose={handleCloseStockHistoryModal}
          />
          <ConfirmationModalWithFormik
            key="add-stock-modal"
            open={!!displayAddStockModal}
            formik={addStockFormik}
            title={`Ajout de stock au lieu de stockage "${displayAddStockModal?.name}".`}
            handleClose={() => setDisplayAddStockModal(undefined)}
            yesText="Ajouter au stock"
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                value={addStockFormik.values.actionDate}
                onChange={(actionDate) => {
                  addStockFormik.setFieldValue("actionDate", actionDate);
                }}
                slotProps={{ textField: { fullWidth: true } }}
              />
            </LocalizationProvider>

            <TextField
              label="Quantité"
              type="number"
              name="actionQuantity"
              inputProps={{ min: "1", step: "1" }}
              value={addStockFormik.values.actionQuantity}
              onChange={addStockFormik.handleChange}
              fullWidth
              className={classes.textField}
              helperText={
                addStockFormik.errors.actionQuantity &&
                addStockFormik.touched.actionQuantity &&
                addStockFormik.errors.actionQuantity
              }
              error={
                !!addStockFormik.errors.actionQuantity &&
                addStockFormik.touched.actionQuantity
              }
            />
          </ConfirmationModalWithFormik>
          {displayUseStockModal && (
            <ConfirmationModalWithFormik
              key="use-stock-modal"
              open={!!displayUseStockModal}
              formik={useStockFormik}
              title={`Utilisation du stock au lieu de stockage "${displayUseStockModal.name}" de l'entrepôt "${
                warehouses[
                  warehouses.findIndex(
                    (warehouse) =>
                      warehouse.id === useStockFormik.values.warehouseId,
                  )
                ].name
              }".`}
              handleClose={() => setDisplayUseStockModal(undefined)}
              yesText="Utiliser"
            >
              <Typography>
                Il reste actuellement{" "}
                <Pluralize
                  singular="produit"
                  count={getPlaceQuantity(displayUseStockModal.id)}
                />
                .
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                {productHasOpeningDate ? (
                  <DatePicker
                    label="Date d'ouverture"
                    value={moment(useStockFormik.values.actionDate)}
                    onChange={(actionDate) => {
                      useStockFormik.setFieldValue("actionDate", actionDate);
                    }}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                ) : (
                  <DateTimePicker
                    open={isOpenModalChooseUseDate}
                    ampm={false}
                    label="Date d'utilisation"
                    format="DD/MM/YYYY HH:mm"
                    value={useStockFormik.values.actionDate}
                    onChange={(actionDate) => {
                      useStockFormik.setFieldValue("actionDate", actionDate);
                    }}
                    // onClick={() => setIsOpenModalChooseUseDate(true)}
                    onClose={() => setIsOpenModalChooseUseDate(false)}
                    // InputProps={{
                    //   readOnly: true,
                    // }}
                    // fullWidth
                  />
                )}
              </LocalizationProvider>
              <TextField
                label="Quantité"
                type="number"
                name="actionQuantity"
                inputProps={{
                  min: "1",
                  step: "1",
                  max: getPlaceQuantity(displayUseStockModal.id),
                }}
                value={useStockFormik.values.actionQuantity}
                onChange={useStockFormik.handleChange}
                fullWidth
                className={classes.textField}
                helperText={
                  useStockFormik.errors.actionQuantity &&
                  useStockFormik.touched.actionQuantity &&
                  useStockFormik.errors.actionQuantity
                }
                error={
                  !!useStockFormik.errors.actionQuantity &&
                  useStockFormik.touched.actionQuantity
                }
              />
            </ConfirmationModalWithFormik>
          )}
          <Title />
          {displayPlaces &&
            displayPlaces.map((warehouse: IWarehouse) => [
              <Grid
                key={warehouse.id}
                container
                className={classes.displayPlaceContainer}
              >
                <Grid item xs={12}>
                  <Paper
                    className={classes.displayPlacePaperContainer}
                    elevation={0}
                  >
                    <Badge
                      max={9999}
                      badgeContent={getWarehouseQuantity(warehouse.id)}
                      showZero
                      color="primary"
                      classes={{
                        root: classes.badgeRoot,
                      }}
                      onClick={() => {
                        setWarehouseForStockQuantityModal({
                          id: warehouse.id,
                          name: warehouse.name,
                        });
                        setIsOpenStockQuantityModal(true);
                      }}
                    >
                      <LinkUi
                        to={`/warehouses/${warehouse.id}`}
                        component={Link}
                        underline="none"
                      >
                        <div className={classes.linkGoToWarehouseOrPlaceDetail}>
                          <Grid container>
                            <Grid
                              item
                              className={classes.warehouseImageWrapper}
                            >
                              <WarehouseImage
                                loaderSize={80}
                                maxWidth={150}
                                maxHeight={150}
                                rootStyle={classes.warehouseImage}
                                warehouseId={warehouse.id}
                              />
                            </Grid>
                            <Grid
                              item
                              xs
                              className={classes.warehouseTitleContainer}
                            >
                              <Typography
                                className={classes.warehouseTitle}
                                variant="h4"
                              >
                                {warehouse.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </LinkUi>
                    </Badge>
                    <Divider />
                    <Grid
                      className={classes.placesOnWarehouseContainer}
                      container
                      spacing={5}
                    >
                      {warehouse.places &&
                        warehouse.places.map((placeElt: IPlace) => (
                          <Grid key={placeElt.id} item lg={4} md={6} xs={12}>
                            <Paper
                              elevation={0}
                              classes={{ root: classes.paperPlace }}
                            >
                              <Badge
                                max={9999}
                                badgeContent={getPlaceQuantity(placeElt.id)}
                                showZero
                                color="primary"
                                classes={{
                                  root: classes.badgeRoot,
                                }}
                                onClick={() => {
                                  setPlaceForStockQuantityModal({
                                    id: placeElt.id,
                                    name: placeElt.name,
                                  });
                                  setIsOpenStockQuantityModal(true);
                                }}
                              >
                                <LinkUi
                                  to={`/places/${placeElt.id}`}
                                  component={Link}
                                  underline="none"
                                >
                                  <div
                                    className={
                                      classes.linkGoToWarehouseOrPlaceDetail
                                    }
                                  >
                                    <div className={classes.placeImageWrapper}>
                                      <PlaceImage
                                        rootStyle={classes.placeImage}
                                        placeId={placeElt.id}
                                      />
                                    </div>
                                    <Typography
                                      className={classes.placeTitle}
                                      variant="h4"
                                    >
                                      {placeElt.name}
                                    </Typography>
                                  </div>
                                </LinkUi>
                              </Badge>
                              <Divider />
                              <Grid
                                className={classes.placeFooterContainer}
                                container
                                justifyContent="space-between"
                                direction="row-reverse"
                              >
                                <Button
                                  color="secondary"
                                  size="small"
                                  startIcon={<AddIcon />}
                                  onClick={() => {
                                    addStockFormik.resetForm();
                                    setDisplayAddStockModal(placeElt);
                                  }}
                                  disabled={isFetchingStocksQuantityForProduct}
                                >
                                  Ajouter du stock
                                </Button>
                                <Button
                                  color="primary"
                                  size="small"
                                  startIcon={<RemoveShoppingCartIcon />}
                                  onClick={() => {
                                    useStockFormik.resetForm({
                                      values:
                                        getUseStockInitialValues(placeElt),
                                    });
                                    setDisplayUseStockModal(placeElt);
                                  }}
                                  disabled={
                                    isFetchingStocksQuantityForProduct ||
                                    parseInt(
                                      getPlaceQuantity(placeElt.id).toString(),
                                    ) <= 0
                                  }
                                >
                                  Utiliser du stock
                                </Button>
                              </Grid>
                            </Paper>
                          </Grid>
                        ))}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>,
            ])}
        </>
      )}
    </Paper>
  );
};

export default ProductPlaces;
