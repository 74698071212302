import MainLayout from "../../layouts/mainLayout/MainLayout";
import WarehousesListing from "../../components/Warehouses/WarehousesListing";

interface IHomeWarehousesProps {
  random: number;
}

const HomeWarehouses = (props: IHomeWarehousesProps) => {
  const { random } = props;

  return (
    <MainLayout>
      <WarehousesListing key={random.toString()} />
    </MainLayout>
  );
};

export default HomeWarehouses;
