import Grid from "@mui/material/Grid";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdateWarehouse from "../../components/Warehouses/CreateOrUpdateWarehouse";

const CreateWarehouse = () => {
  return (
    <MainLayout>
      <Grid container spacing={16} justifyContent="center">
        <Grid item xs={12} sm={11} md={8} lg={6} xl={4}>
          <CreateOrUpdateWarehouse title="Ajouter un entrepôt" />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default CreateWarehouse;
