import axios from "axios";
import config from "../config";
import { TFilter, TJoin, TSort } from "types/query.type";

export const getStockOpenings = (join: TJoin, filter: TFilter, sort: TSort) =>
  axios.get(`${config.API.HOST}/stockopening`, {
    params: {
      join,
      filter,
      sort,
    },
  });

export default {
  getStockOpenings,
};
