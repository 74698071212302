import { useState } from "react";
import { useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

interface ISearchProps {
  placeholder: string;
  callback: (value: string) => void;
  disabled: boolean;
  className?: string;
}

const Search = (props: ISearchProps) => {
  const { placeholder, callback, disabled, className } = props;

  const theme = useTheme();

  const [searchValue, setSearchValue] = useState("");

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    callback(value);
    setSearchValue(value);
  };

  const handleClickIcon = () => {
    if (searchValue.length > 0) {
      callback("");
      setSearchValue("");
    }
  };

  return (
    <Paper
      sx={{
        padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
        display: "flex",
        alignItems: "center",
      }}
      className={className}
    >
      <InputBase
        sx={{
          marginLeft: 0.25,
          flex: 1,
        }}
        placeholder={placeholder}
        inputProps={{ "aria-label": `search-${Math.random()}` }}
        value={searchValue}
        onChange={handleChangeSearch}
        disabled={disabled}
      />

      <IconButton
        sx={{ padding: 1.25 }}
        aria-label={`search-icon-${Math.random()}`}
        onClick={handleClickIcon}
      >
        {searchValue.length > 0 || disabled ? (
          <CloseIcon color={disabled ? "disabled" : "inherit"} />
        ) : (
          <SearchIcon />
        )}
      </IconButton>
    </Paper>
  );
};

export default Search;
