import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText, {
  DialogContentTextProps,
} from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useTheme, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";

interface ConfirmationModalWithFormikProps {
  open: DialogProps["open"];
  handleClose: () => void;
  title: string;
  yesText?: string;
  noText?: string;
  children: DialogContentTextProps["children"];
  formik: ReturnType<typeof useFormik<any>>;
}

const ConfirmationModalWithFormik = (
  props: ConfirmationModalWithFormikProps,
) => {
  const {
    open,
    handleClose,
    title,
    yesText = "Valider",
    noText = "Annuler",
    children,
    formik,
  } = props;

  const modalDialogTitleId = Math.random().toString();
  const modalDialogDescriptionId = Math.random().toString();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseModal = () => {
    if (!formik.isSubmitting) {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      TransitionComponent={(props) => <Slide direction="up" {...props} />}
      transitionDuration={{
        enter: formik.isSubmitting ? 0 : 300,
      }}
      keepMounted
      onClose={handleCloseModal}
      aria-labelledby={modalDialogTitleId}
      aria-describedby={modalDialogDescriptionId}
    >
      <DialogTitle id={modalDialogTitleId}>
        <strong>{title}</strong>
      </DialogTitle>

      <form onSubmit={formik.handleSubmit} noValidate>
        <DialogContent>
          <DialogContentText id={modalDialogDescriptionId}>
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting && (
              <CircularProgress color="inherit" size={14} />
            )}
            {!formik.isSubmitting && yesText}
          </Button>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={formik.isSubmitting}
          >
            {noText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfirmationModalWithFormik;
