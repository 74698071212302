import { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import PageLoader from "../Loaders/PageLoader";
import { usePlacePicture } from "../../Hooks/places.hook";
import { IPlace } from "types/place.type";
import { TImage } from "types/image.type";

const Photo = require("../../public/img/imgnotfound.png");

interface IPlaceImageProps {
  placeId: IPlace["id"];
  rootStyle: string;
}

const PlaceImage = (props: IPlaceImageProps) => {
  const { placeId, rootStyle } = props;

  const [image, setImage] = useState<TImage>();

  const {
    placePicture,
    isFetching: isLoadingPlacePicture,
    isError: isErrorPlacePicture,
  } = usePlacePicture({
    placeId,
  });

  useEffect(() => {
    if (!isLoadingPlacePicture) {
      if (isErrorPlacePicture && !placePicture) {
        setImage(Photo);
      } else {
        Resizer.imageFileResizer(
          placePicture,
          250,
          500,
          "PNG",
          100,
          0,
          (picture) => {
            setImage(picture);
          },
          "base64",
        );
      }
    }
  }, [isLoadingPlacePicture, isErrorPlacePicture, placePicture]);

  return isLoadingPlacePicture || (!isLoadingPlacePicture && !image) ? (
    <PageLoader className={rootStyle} />
  ) : (
    <img
      className={rootStyle}
      alt={image?.toString()}
      src={image?.toString()}
    />
  );
};

export default PlaceImage;
