import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinkUi from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import PageLoader from "../Loaders/PageLoader";
import PlaceImage from "../Places/PlaceImage";
import { Theme } from "@mui/material";
import { IPlace } from "types/place.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  noLinkeds: {
    margin: "auto",
    textAlign: "center",
  },
  placesToPlaceContainer: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
    textAlign: "right",
  },
  placeImageWrapper: {
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  placeImage: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  placeFooterContainer: {
    wordBreak: "break-word",
    padding: theme.spacing(1, 1),
    textAlign: "right",
  },
  linkGoToPlaceDetail: {
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  placeName: {
    fontSize: "18px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
  },
}));

const Title = () => {
  return (
    <Typography component="h1" variant="h5">
      Lieux de stockage
    </Typography>
  );
};

interface IWarehousePlacesProps {
  loading?: boolean;
  places: Array<IPlace>;
}

const WarehousePlaces = (props: IWarehousePlacesProps) => {
  const { loading = false, places } = props;

  const { classes } = useStyles();

  if (!loading && places.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Title />
        <div className={classes.noLinkeds}>
          <div>Aucun lieu de stockage lié à l&apos;entrepôt.</div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Title />
          <Grid
            className={classes.placesToPlaceContainer}
            container
            spacing={4}
          >
            {places.map((place) => (
              <Grid item key={place.id} lg={4} md={6} xs={12}>
                <Paper elevation={0}>
                  <LinkUi
                    to={`/places/${place.id}`}
                    component={Link}
                    underline="none"
                  >
                    <div className={classes.linkGoToPlaceDetail}>
                      <div className={classes.placeImageWrapper}>
                        <PlaceImage
                          rootStyle={classes.placeImage}
                          placeId={place.id}
                        />
                      </div>
                      <Typography className={classes.placeName} variant="h4">
                        {place.name}
                      </Typography>
                    </div>
                  </LinkUi>
                  <Divider />
                  <Grid
                    className={classes.placeFooterContainer}
                    container
                    justifyContent="space-between"
                  >
                    AUCUNE ACTION POSSIBLE ACTUELLEMENT
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default WarehousePlaces;
