import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "tss-react/mui";
import { useQueryClient } from "@tanstack/react-query";
import PageLoader from "../Loaders/PageLoader";
import WarehouseImage from "./WarehouseImage";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import {
  useCreateWarehouse,
  useEditWarehouse,
} from "../../Hooks/warehouses.hook";
import { IWarehouse } from "types/warehouse.type";
import { AxiosRequestConfig } from "axios";

const useStyles = makeStyles()((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(8),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  imageWrapper: {
    display: "flex",
    height: "250px",
  },
  image: {
    margin: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

interface ICreateOrUpdateWarehouseProps {
  loading?: boolean;
  warehouseUpdate?: IWarehouse;
  title: string;
}

const CreateOrUpdateWarehouse = (props: ICreateOrUpdateWarehouseProps) => {
  const { loading = false, warehouseUpdate = null, title } = props;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { classes } = useStyles();
  const initialValues = {
    warehouseName: warehouseUpdate?.name || "",
    warehousePicture: "",
  };

  const { mutate: createWarehouse, isPending: isPendingCreateWarehouse } =
    useCreateWarehouse({
      onSuccess: ({ data }) => {
        enqueueSnackbar(`L'entrepôt "${data.name}" a été ajouté.`, {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
        navigate(`/warehouses/${data.id}`);
      },
      onError: ({ config }) => {
        const { data } = config as AxiosRequestConfig;

        enqueueSnackbar(
          `Une erreur est survenue lors de l'ajout de l'entrepôt "${data.get("name")}".`,
          {
            variant: "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
      },
    });

  const { mutate: editWarehouse, isPending: isPendingEditWarehouse } =
    useEditWarehouse({
      onSuccess: ({ data }) => {
        enqueueSnackbar(
          `L'entrepôt "${warehouseUpdate?.name}" a été mis à jour.`,
          {
            variant: "success",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );

        if (warehouseUpdate?.pictureFileName !== data?.pictureFileName) {
          queryClient.invalidateQueries({
            queryKey: [
              "warehouse-picture",
              { warehouseId: warehouseUpdate?.id },
            ],
            exact: true,
          });
        }

        queryClient.refetchQueries({
          queryKey: ["warehouse", { warehouseId: warehouseUpdate?.id }],
          exact: true,
        });

        queryClient.refetchQueries({
          queryKey: [
            "warehouses-history",
            { warehouseId: warehouseUpdate?.id },
          ],
          exact: true,
        });
      },
      onError: () => {
        enqueueSnackbar(
          `Une erreur est survenue lors de la mise à jour de l'entrepôt "${warehouseUpdate?.name}".`,
          {
            variant: "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
      },
    });

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          {warehouseUpdate && (
            <div className={classes.imageWrapper}>
              <WarehouseImage
                rootStyle={classes.image}
                warehouseId={warehouseUpdate.id}
              />
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={({ warehouseName, warehousePicture }) => {
              const formData = new FormData();
              formData.append("name", warehouseName);
              if (warehousePicture) {
                formData.append("picture", warehousePicture);
              }

              if (!warehouseUpdate) {
                createWarehouse({ warehouse: formData });
              } else {
                editWarehouse({
                  warehouseId: warehouseUpdate.id,
                  warehouse: formData,
                });
              }
            }}
            validationSchema={yup.object().shape({
              warehouseName: yup
                .string()
                .required(`Le nom de l'entrepôt est requis.`),
              warehousePicture: yup
                .mixed<File>()
                .test(
                  "fileFormat",
                  "Le format de l'image n'est pas valide.",
                  (value) => (value ? value.type.startsWith("image/") : true),
                ),
            })}
            render={({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  label={`Nom de l'entrepôt`}
                  type="text"
                  name="warehouseName"
                  autoFocus
                  value={values.warehouseName}
                  onChange={handleChange}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.warehouseName &&
                    touched.warehouseName &&
                    errors.warehouseName
                  }
                  error={!!errors.warehouseName && touched.warehouseName}
                />

                <TextField
                  label={warehouseUpdate ? "Mettre à jour l'image" : "Image"}
                  inputProps={{ accept: "image/*" }}
                  InputLabelProps={{ shrink: true }}
                  type="file"
                  name="warehousePicture"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { files } = event.currentTarget;

                    setFieldValue(
                      "warehousePicture",
                      files && files[0] ? files[0] : undefined,
                    );
                  }}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.warehousePicture &&
                    touched.warehousePicture &&
                    errors.warehousePicture
                  }
                  error={
                    !!errors.warehousePicture && !!touched.warehousePicture
                  }
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isPendingEditWarehouse || isPendingCreateWarehouse}
                >
                  {(isPendingEditWarehouse || isPendingCreateWarehouse) && (
                    <CircularProgress color="inherit" size={14} />
                  )}
                  {(!isPendingEditWarehouse || !isPendingCreateWarehouse) &&
                    (warehouseUpdate ? "Mettre à jour" : "Ajouter")}
                </Button>
              </form>
            )}
          />
        </>
      )}
    </Paper>
  );
};

export default CreateOrUpdateWarehouse;
