import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

interface PageLoaderProps {
  size?: CircularProgressProps["size"];
  className?: string;
}

const PageLoader = (props: PageLoaderProps) => {
  const { size = 200, className = "" } = props;

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={className}
    >
      <CircularProgress disableShrink size={size} thickness={2.5} />
    </Box>
  );
};

export default PageLoader;
