import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Axios from "axios";
import Keycloak from "keycloak-js";
import App from "./App";

const keycloak = new Keycloak("/keycloak.json");

// Authent interceptor
Axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const newConfig = config;
    newConfig.headers.authorization = `Bearer ${keycloak.token}`;
    return newConfig;
  },
  (error) => Promise.reject(error),
);

keycloak
  .init({ onLoad: "login-required" })
  .then(() => {
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement,
    );
    root.render(
      <StrictMode>
        <App />
      </StrictMode>,
    );
  })
  .catch((err) => {
    console.error(err);
    console.error("Unable to load keycloak.json");
    if (err && err.error === "access_denied") {
      keycloak.logout();
    }
  });
