import { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import PageLoader from "../Loaders/PageLoader";
import { useWarehousePicture } from "../../Hooks/warehouses.hook";
import { IWarehouse } from "types/warehouse.type";
import { TImage } from "types/image.type";

const Photo = require("../../public/img/imgnotfound.png");

interface IWarehouseImageProps {
  warehouseId: IWarehouse["id"];
  rootStyle: string;
  maxWidth?: number;
  maxHeight?: number;
  loaderSize?: number;
}

const WarehouseImage = (props: IWarehouseImageProps) => {
  const {
    warehouseId,
    rootStyle,
    maxWidth = 250,
    maxHeight = 500,
    loaderSize = 200,
  } = props;

  const [image, setImage] = useState<TImage>();

  const {
    warehousePicture,
    isFetching: isLoadingWarehousePicture,
    isError: isErrorWarehousePicture,
  } = useWarehousePicture({
    warehouseId,
  });

  useEffect(() => {
    if (!isLoadingWarehousePicture) {
      if (isErrorWarehousePicture && !warehousePicture) {
        setImage(Photo);
      } else {
        Resizer.imageFileResizer(
          warehousePicture,
          maxWidth,
          maxHeight,
          "PNG",
          100,
          0,
          (picture) => {
            setImage(picture);
          },
          "base64",
        );
      }
    }
  }, [
    isLoadingWarehousePicture,
    isErrorWarehousePicture,
    warehousePicture,
    maxWidth,
    maxHeight,
  ]);

  return isLoadingWarehousePicture || (!isLoadingWarehousePicture && !image) ? (
    <div className={rootStyle}>
      <PageLoader size={loaderSize} />
    </div>
  ) : (
    <img
      className={rootStyle}
      alt={image?.toString()}
      src={image?.toString()}
    />
  );
};

export default WarehouseImage;
