import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { blue, red } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import moment from "moment";
import "moment/locale/fr";
import { makeStyles } from "tss-react/mui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Home from "./routes/Home";
import HomeProducts from "./routes/products/HomeProducts";
import CreateProduct from "./routes/products/CreateProduct";
import ProductDetail from "./routes/products/ProductDetail";
import HomeWarehouses from "./routes/warehouses/HomeWarehouses";
import CreateWarehouse from "./routes/warehouses/CreateWarehouse";
import WarehouseDetail from "./routes/warehouses/WarehouseDetail";
import HomePlaces from "./routes/places/HomePlaces";
import CreatePlace from "./routes/places/CreatePlace";
import PlaceDetail from "./routes/places/PlaceDetail";

moment.locale("fr");

const defaultTheme = createTheme({
  palette: {
    primary: red,
    secondary: blue,
    // border: "#DFE3E8",
  },
  typography: {
    fontFamily: "Courier Helvetica",
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          minHeight: 30,
          minWidth: 30,
          borderRadius: 15,
          fontSize: "1rem",
        },
      },
    },
  },
});

const useStyles = makeStyles()((theme) => ({
  snackbarRoot: {
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "20%",
    },
  },
  snackbarBase: {
    width: "100%",
    flexFlow: "row",
  },
}));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: (failureCount, error: any) => {
        if (failureCount >= 1 || error?.response?.status === 404) {
          return false;
        }
        return true;
      },
    },
  },
});

const App = () => {
  const [theme] = useState(defaultTheme);
  const { classes } = useStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          classes={{
            root: classes.snackbarRoot,
            containerRoot: classes.snackbarBase,
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/products"
                element={<HomeProducts random={new Date().getTime()} />}
              />
              <Route path="/new-product" element={<CreateProduct />} />
              <Route path="/products/:productId" element={<ProductDetail />} />
              <Route
                path="/warehouses"
                element={<HomeWarehouses random={new Date().getTime()} />}
              />
              <Route path="/new-warehouse" element={<CreateWarehouse />} />
              <Route
                path="/warehouses/:warehouseId"
                element={<WarehouseDetail />}
              />
              <Route
                path="/places"
                element={<HomePlaces random={new Date().getTime()} />}
              />
              <Route path="/new-place" element={<CreatePlace />} />
              <Route path="/places/:placeId" element={<PlaceDetail />} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
