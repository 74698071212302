import { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { makeStyles } from "tss-react/mui";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/lab/Autocomplete";
import isUuid from "is-uuid";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import PageLoader from "../Loaders/PageLoader";
import { useAddProductToAPlace } from "../../Hooks/products.hook";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import { Theme } from "@mui/material";
import { IProduct } from "types/product.type";
import { IWarehouse } from "types/warehouse.type";
import { IPlace } from "types/place.type";
import { AxiosResponse } from "axios";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%",
  },
  noWarehouseOrPlace: {
    margin: "auto",
    textAlign: "center",
  },
}));

interface IProductAddPlaceProps {
  loading?: boolean;
  product: IProduct;
  warehouses: Array<IWarehouse>;
}

const ProductAddPlace = (props: IProductAddPlaceProps) => {
  const { loading = false, product, warehouses } = props;

  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  interface IInitialValues {
    warehouseId: string;
    placeId: string;
    placeName?: IPlace["name"];
  }
  const initialValues: IInitialValues = {
    warehouseId: "",
    placeId: "",
  };

  const [placeName, setPlaceName] = useState<IPlace["name"] | null>(null);
  const [resetForm, setResetForm] = useState<() => void>(() => {});

  const { mutate: addProductToAPlace, isPending: isPendingAddProductToAPlace } =
    useAddProductToAPlace({
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: ["products-history", { productId: product.id }],
          exact: true,
        });

        queryClient.refetchQueries({
          queryKey: ["product", { productId: product.id }],
          exact: true,
        });

        resetForm();
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const productAlreadyLinkedError =
          data &&
          data.title &&
          data.title === "PRODUCT_ALREADY_LINKED_TO_PLACE";
        enqueueSnackbar(
          `${
            productAlreadyLinkedError
              ? `Le produit "${product.name}" est déjà lié au lieu de stockage "${placeName}".`
              : `Une erreur est survenue lors de l'ajout de produit "${product.name}" au lieu de stockage "${placeName}". Veuillez réessayer.`
          }`,
          {
            variant: productAlreadyLinkedError ? "warning" : "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
      },
    });

  if (!loading && warehouses.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Ajouter à un lieu de stockage
        </Typography>
        <div className={classes.noWarehouseOrPlace}>
          <div>
            Aucun {warehouses.length === 0 ? "entrepôt" : "lieu de stockage"}
          </div>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() =>
                navigate(
                  warehouses.length === 0 ? "/new-warehouse" : "/new-place",
                )
              }
            >
              Ajouter un{" "}
              {warehouses.length === 0 ? "entrepôt" : "lieu de stockage"}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Typography component="h1" variant="h5">
            Ajouter à un lieu de stockage
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={(
              { placeId, placeName: newPlaceName },
              { resetForm: newResetForm },
            ) => {
              setPlaceName(newPlaceName || null);
              setResetForm(newResetForm);
              addProductToAPlace({ productId: product.id, placeId });
            }}
            validationSchema={yup.object().shape({
              warehouseId: yup
                .mixed()
                .test(
                  "wrongWarehouseId",
                  "L'entrepôt est requis.",
                  (warehouseId) =>
                    isUuid.anyNonNil(warehouseId?.toString() || ""),
                ),
              placeId: yup
                .mixed()
                .test(
                  "wrongWarehouseId",
                  "Le lieu de stockage est requis.",
                  (placeId) => isUuid.anyNonNil(placeId?.toString() || ""),
                ),
            })}
            render={({
              values,
              touched,
              errors,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form} noValidate>
                <Autocomplete
                  onChange={(_, value) => {
                    setFieldValue("warehouseId", value ? value.id : "");
                    setFieldValue("placeId", "");
                  }}
                  value={
                    warehouses[
                      warehouses.findIndex(
                        (warehouse) => warehouse.id === values.warehouseId,
                      )
                    ] || null
                  }
                  options={warehouses}
                  getOptionLabel={(option) => option.name}
                  disableClearable
                  noOptionsText="Aucun entrepôt"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Entrepôt"
                      fullWidth
                      className={classes.textField}
                      helperText={
                        errors.warehouseId &&
                        touched.warehouseId &&
                        errors.warehouseId
                      }
                      error={!!errors.warehouseId && touched.warehouseId}
                    />
                  )}
                />

                {values.warehouseId && (
                  <Autocomplete
                    onChange={(_, value) => {
                      setFieldValue("placeId", value?.id || "");
                      setFieldValue("placeName", value?.name || null);
                    }}
                    value={
                      warehouses
                        .find(
                          (warehouse) => warehouse.id === values.warehouseId,
                        )
                        ?.places?.find(
                          (place: IPlace) => place.id === values.placeId,
                        ) || undefined
                    }
                    options={
                      warehouses.find(
                        (warehouse) => warehouse.id === values.warehouseId,
                      )?.places || []
                    }
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    noOptionsText="Aucun lieu de stockage"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Lieu de stockage"
                        fullWidth
                        className={classes.textField}
                        helperText={
                          errors.placeId && touched.placeId && errors.placeId
                        }
                        error={!!errors.placeId && touched.placeId}
                      />
                    )}
                  />
                )}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isPendingAddProductToAPlace}
                >
                  {isPendingAddProductToAPlace && (
                    <CircularProgress color="inherit" size={14} />
                  )}
                  {!isPendingAddProductToAPlace &&
                    "Lier le produit à l'entrepôt"}
                </Button>
              </form>
            )}
          />
        </>
      )}
    </Paper>
  );
};

export default ProductAddPlace;
