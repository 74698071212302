import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import LinkUi from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import PageLoader from "../Loaders/PageLoader";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import CloseModal from "../Modals/CloseModal";
import TableComponent from "../Table/TableComponent";
import { formatToLongDateWithYearAndTime } from "../../Utils/MomentUtils";
import { formatActionType } from "../../Utils/StockHistoryUtils";
import {
  useStockHistoryByProductAndPlace,
  useStockHistoryByProductAndWarehouse,
} from "../../Hooks/stock.hook";
import { IProduct } from "types/product.type";
import { IPlace } from "types/place.type";
import { IWarehouse } from "types/warehouse.type";
import { IStockHistory } from "types/stock.type";

interface IStockHistoryModalProps {
  open: boolean;
  productId: IProduct["id"];
  place: IPlace | undefined;
  warehouse: IWarehouse | undefined;
  handleClose: () => void;
}

const StockHistoryModal = (props: IStockHistoryModalProps) => {
  const { open, productId, place, handleClose, warehouse } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [items, setItems] = useState<Array<Array<JSX.Element>>>([]);

  const {
    stockHistoryByProductAndPlace,
    isFetching: isFetchingStockHistoryByProductAndPlace,
    isError: isErrorStockHistoryByProductAndPlace,
    isSuccess: isSuccessStockHistoryByProductAndPlace,
  } = useStockHistoryByProductAndPlace({
    productId,
    placeId: place?.id || "",
    config: { enabled: !!(open && place) },
  });

  const {
    stockHistoryByProductAndWarehouse,
    isFetching: isFetchingStockHistoryByProductAndWarehouse,
    isError: isErrorStockHistoryByProductAndWarehouse,
    isSuccess: isSuccessStockHistoryByProductAndWarehouse,
  } = useStockHistoryByProductAndWarehouse({
    productId,
    warehouseId: warehouse?.id || "",
    config: { enabled: !!(open && !place) },
  });

  const handleNewItems = useCallback(
    (itemsParam: Array<IStockHistory>) => {
      const newItems: Array<Array<JSX.Element>> = [];
      itemsParam.forEach((res) => {
        newItems.push([
          <>{formatToLongDateWithYearAndTime(res.actionDate)}</>,
          <>{formatActionType(res.actionType)}</>,
          <>{res.actionQuantity}</>,
          <>
            {warehouse && (
              <LinkUi to={`/places/${res.place.id}`} component={Link}>
                {res.place.name}
              </LinkUi>
            )}
          </>,
        ]);
      });
      setItems(newItems);
    },
    [warehouse],
  );

  useEffect(() => {
    if (open && place && isSuccessStockHistoryByProductAndPlace) {
      handleNewItems(stockHistoryByProductAndPlace);
    }
  }, [
    open,
    place,
    isSuccessStockHistoryByProductAndPlace,
    stockHistoryByProductAndPlace,
    handleNewItems,
  ]);

  useEffect(() => {
    if (open && !place && isSuccessStockHistoryByProductAndWarehouse) {
      handleNewItems(stockHistoryByProductAndWarehouse);
    }
  }, [
    open,
    warehouse,
    isSuccessStockHistoryByProductAndWarehouse,
    stockHistoryByProductAndWarehouse,
    place,
    handleNewItems,
  ]);

  useEffect(() => {
    if (open && place && isErrorStockHistoryByProductAndPlace) {
      enqueueSnackbar(
        `Une erreur est survenue lors de la récupération de l'historique des stocks pour le lieu de stockage "${place.name}".`,
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [open, place, isErrorStockHistoryByProductAndPlace, enqueueSnackbar]);

  useEffect(() => {
    if (open && !place && isErrorStockHistoryByProductAndWarehouse) {
      enqueueSnackbar(
        `Une erreur est survenue lors de la récupération de l'historique des stocks pour l'entrepôt "${warehouse?.name}".`,
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [
    open,
    place,
    warehouse,
    isErrorStockHistoryByProductAndWarehouse,
    enqueueSnackbar,
  ]);

  return (
    <CloseModal
      key="place-stock-history-modal"
      open={open}
      title={`Historique du produit pour ${
        place ? "le lieu de stockage" : "l'entrepôt"
      } "${place?.name || warehouse?.name}".`}
      handleClose={handleClose}
    >
      {isFetchingStockHistoryByProductAndPlace ||
      isFetchingStockHistoryByProductAndWarehouse ? (
        <PageLoader size={50} />
      ) : (
        <>
          <Typography>
            {items.length === 0
              ? "Aucune action réalisée."
              : "Actions réalisées sur le stock :"}
          </Typography>
          {items.length > 0 && (
            <TableComponent
              columnsName={[
                "Date",
                "Action",
                "Quantité",
                warehouse ? "Entrepôt" : "",
              ]}
              items={items}
            />
          )}
        </>
      )}
    </CloseModal>
  );
};

export default StockHistoryModal;
