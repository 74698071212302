import { useQuery } from "@tanstack/react-query";
import { getStockOpenings } from "../services/StockOpeningService";
import { TFilter, TJoin, TSort } from "types/query.type";

interface IUseStockOpeningsProps {
  join?: TJoin;
  filter?: TFilter;
  sort?: TSort;
}

const useStockOpenings = (props: IUseStockOpeningsProps) => {
  const { join = "", filter = "", sort = "" } = props;

  const { data, ...rest } = useQuery({
    queryKey: ["stock-openings"],
    queryFn: async () => getStockOpenings(join, filter, sort),
  });

  return {
    stockOpenings: data?.data?.data || [],
    ...rest,
  };
};

export default useStockOpenings;
