import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useTheme, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

interface ConfirmationModalProps {
  open: DialogProps["open"];
  handleClose: () => void;
  yesCallback: () => void;
  title: string;
  description: React.ReactNode;
  loadingSuccessCallback?: boolean;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    open,
    handleClose,
    yesCallback,
    title,
    description,
    loadingSuccessCallback = false,
  } = props;

  const modalDialogTitleId = Math.random().toString();
  const modalDialogDescriptionId = Math.random().toString();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      TransitionComponent={(props) => <Slide direction="up" {...props} />}
      transitionDuration={{
        enter: loadingSuccessCallback ? 0 : 300,
      }}
      keepMounted
      onClose={handleClose}
      aria-labelledby={modalDialogTitleId}
      aria-describedby={modalDialogDescriptionId}
    >
      <DialogTitle id={modalDialogTitleId}>
        <strong>{title}</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={modalDialogDescriptionId}>
          <>{description}</>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={yesCallback}
          color="primary"
          variant="contained"
          disabled={loadingSuccessCallback}
        >
          {loadingSuccessCallback && (
            <CircularProgress color="inherit" size={14} />
          )}
          {!loadingSuccessCallback && "Oui"}
        </Button>
        <Button
          onClick={handleClose}
          color="secondary"
          disabled={loadingSuccessCallback}
        >
          Non
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
