import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarKey, useSnackbar } from "notistack";

interface SnackbarCloseIconProps {
  snackbarKey: SnackbarKey;
}

const SnackbarCloseIcon = (props: SnackbarCloseIconProps) => {
  const { snackbarKey } = props;
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={() => closeSnackbar(snackbarKey)}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default SnackbarCloseIcon;
