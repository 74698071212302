import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { formatToLongDateWithYearAndTime } from "../../Utils/MomentUtils";
import { formatToWord } from "../../Utils/ActionTypeUtils";
import PageLoader from "../Loaders/PageLoader";
import TableComponent from "../Table/TableComponent";
import { Theme } from "@mui/material";
import { IStockHistory } from "types/stock.type";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  noHistory: {
    margin: "auto",
    textAlign: "center",
  },
  tableComponentContainer: {
    margin: theme.spacing(2, 0),
  },
}));

const Title = () => {
  return (
    <Typography component="h1" variant="h5">
      Historique
    </Typography>
  );
};

interface IResourceHistoryProps {
  loading?: boolean;
  history: Array<IStockHistory>;
}

const ResourceHistory = (props: IResourceHistoryProps) => {
  const { loading = false, history } = props;

  const { classes } = useStyles();

  const [items, setItems] = useState<Array<Array<JSX.Element>>>([]);

  useEffect(() => {
    const newItems: Array<Array<JSX.Element>> = [];
    history.forEach((elt: IStockHistory) => {
      newItems.push([
        <>{formatToLongDateWithYearAndTime(elt.createdAt)}</>,
        formatToWord(elt),
      ]);
    });
    setItems(newItems);
  }, [history]);

  if (!loading && history.length === 0) {
    return (
      <Paper className={classes.paper}>
        <Title />
        <div className={classes.noHistory}>
          <div>Aucun historique.</div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Title />
          <Grid container className={classes.tableComponentContainer}>
            <Grid item xs={12}>
              <TableComponent columnsName={["Date", "Action"]} items={items} />
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default ResourceHistory;
