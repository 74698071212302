import { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import PageLoader from "../Loaders/PageLoader";
import { useProductPicture } from "../../Hooks/products.hook";
import { IProduct } from "types/product.type";
import { TImage } from "types/image.type";

const Photo = require("../../public/img/imgnotfound.png");

interface IProductImageProps {
  productId: IProduct["id"];
  rootStyle: string;
}

const ProductImage = (props: IProductImageProps) => {
  const { productId, rootStyle } = props;

  const [image, setImage] = useState<TImage>();

  const {
    productPicture,
    isFetching: isLoadingProductPicture,
    isError: isErrorProductPicture,
  } = useProductPicture({
    productId,
  });

  useEffect(() => {
    if (!isLoadingProductPicture) {
      if (isErrorProductPicture && !productPicture) {
        setImage(Photo);
      } else {
        Resizer.imageFileResizer(
          productPicture,
          250,
          500,
          "PNG",
          100,
          0,
          (picture) => {
            setImage(picture);
          },
          "base64",
        );
      }
    }
  }, [isLoadingProductPicture, isErrorProductPicture, productPicture]);

  return isLoadingProductPicture || (!isLoadingProductPicture && !image) ? (
    <PageLoader className={rootStyle} />
  ) : (
    <img
      className={rootStyle}
      alt={image?.toString()}
      src={image?.toString()}
    />
  );
};

export default ProductImage;
