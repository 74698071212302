import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText, {
  DialogContentTextProps,
} from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useTheme, useMediaQuery } from "@mui/material";

interface CloseModalProps {
  open: DialogProps["open"];
  handleClose: () => void;
  title: string;
  children: DialogContentTextProps["children"];
  transitionDurationEnter?: number;
}

const CloseModal = (props: CloseModalProps) => {
  const {
    open,
    handleClose,
    title,
    children,
    transitionDurationEnter = 300,
  } = props;

  const modalDialogTitleId = Math.random().toString();
  const modalDialogContentId = Math.random().toString();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      TransitionComponent={(props) => <Slide direction="up" {...props} />}
      transitionDuration={{
        enter: transitionDurationEnter,
      }}
      keepMounted
      onClose={handleClose}
      aria-labelledby={modalDialogTitleId}
      aria-describedby={modalDialogContentId}
    >
      <DialogTitle id={modalDialogTitleId}>
        <strong>{title}</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={modalDialogContentId}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseModal;
