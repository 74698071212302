import Typography from "@mui/material/Typography";
import LinkUi from "@mui/material/Link";
import { Link } from "react-router-dom";
// @ts-ignore
import Pluralize from "react-pluralize";
import CloseModal from "../Modals/CloseModal";
import { IWarehouse } from "types/warehouse.type";
import { IPlace } from "types/place.type";

interface IDeleteWarehousePlacesLinkedsModalProps {
  warehouse: IWarehouse;
  hancleClose: () => void;
  placesLinkeds: Array<IPlace>;
}

const DeleteWarehousePlacesLinkedsModal = (
  props: IDeleteWarehousePlacesLinkedsModalProps,
) => {
  const { placesLinkeds, warehouse, hancleClose } = props;

  if (placesLinkeds.length === 0) {
    return "Aucun lieu de stockage lié à l'entrepôt.";
  }

  return (
    placesLinkeds.length > 0 && (
      <CloseModal
        key="delete-warehouse-confirmation-modal"
        open
        title={`Suppression d'un entrepôt.`}
        handleClose={hancleClose}
      >
        <Typography>{`Il n'est pas possible de supprimer l'entrepôt "${warehouse.name}".`}</Typography>
        <Typography>
          {`Il est lié à `}
          <Pluralize
            singular="lieux de stockage"
            count={placesLinkeds.length}
          />
          {` :`}
        </Typography>
        <ul>
          {placesLinkeds.map((place) => (
            <li key={place.id}>
              <LinkUi
                to={`/places/${place.id}`}
                component={Link}
                underline="none"
              >
                {place.name}
              </LinkUi>
            </li>
          ))}
        </ul>
      </CloseModal>
    )
  );
};

export default DeleteWarehousePlacesLinkedsModal;
