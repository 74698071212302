import MainLayout from "../../layouts/mainLayout/MainLayout";
import ProductsListing from "../../components/Products/ProductsListing";

interface IHomeProductsProps {
  random: number;
}

const HomeProducts = (props: IHomeProductsProps) => {
  const { random } = props;

  return (
    <MainLayout>
      <ProductsListing key={random.toString()} />
    </MainLayout>
  );
};

export default HomeProducts;
