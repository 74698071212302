import Grid from "@mui/material/Grid";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdateProduct from "../../components/Products/CreateOrUpdateProduct";

const CreateProduct = () => (
  <MainLayout>
    <Grid container spacing={16} justifyContent="center">
      <Grid item xs={12} sm={11} md={8} lg={6} xl={4}>
        <CreateOrUpdateProduct title="Ajouter un produit" />
      </Grid>
    </Grid>
  </MainLayout>
);

export default CreateProduct;
