import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import LinkUi from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import DeleteIcon from "@mui/icons-material/Delete";
// @ts-ignore
import Pluralize from "react-pluralize";
import { makeStyles } from "tss-react/mui";
import ProductImage from "./ProductImage";
import Search from "../Search";
import PageLoader from "../Loaders/PageLoader";
import ConfirmationModal from "../Modals/ConfirmationModal";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import { useDeleteProduct, useProducts } from "../../Hooks/products.hook";
import { IProduct } from "types/product.type";
import { AxiosResponse } from "axios";

const useStyles = makeStyles()((theme) => ({
  rootPaper: {
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  squared: {
    borderRadius: 0,
  },
  outlined: {
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  imageWrapper: {
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  title: {
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "16px",
    height: theme.spacing(4),
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  paperFooter: {
    padding: theme.spacing(1, 1),
    textAlign: "right",
  },
  linkGoToProductDetail: {
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  search: {
    [theme.breakpoints.up("md")]: {
      width: 500,
    },
  },
  topContainer: {
    marginBottom: theme.spacing(4),
  },
  fullWidthForXs: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  deleteIcon: {
    cursor: "pointer",
  },
  placeOpenPopover: {
    cursor: "pointer",
  },
  placePopover: {
    margin: theme.spacing(1),
  },
}));

interface IProductsListingProps {
  key: string;
}

const ProductsListing = (props: IProductsListingProps) => {
  const { key } = props;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const [productToDelete, setProductToDelete] = useState<IProduct>();
  const [search, setSearch] = useState<string | boolean>(false);

  const {
    products,
    isFetching,
    isError,
    refetch: refetchProducts,
  } = useProducts({
    join: "places",
    filter: search ? `name||cont||${search}` : "",
  });

  const { mutate: deleteProduct, isPending: isPendingDeleteProduct } =
    useDeleteProduct({
      onSuccess: () => {
        enqueueSnackbar(
          `Le produit "${productToDelete?.name}" a été supprimé.`,
          {
            variant: "success",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
        setProductToDelete(undefined);
        refetchProducts();
      },
      onError: ({ response }) => {
        const { data, status } = response as AxiosResponse;

        enqueueSnackbar(
          status === 403 &&
            data.title &&
            data.title === "PRODUCT_HAVE_QUANTITY_FOR_A_LINKED_PLACE"
            ? `Le produit "${productToDelete?.name}" ne peut pas être supprimé car un lieu de stockage contient du stock.`
            : `Une erreur est survenue lors de la suppression du produit "${productToDelete?.name}".`,
          {
            variant: "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
        setProductToDelete(undefined);
      },
    });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des produits.",
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
    }
  }, [enqueueSnackbar, isError]);

  useEffect(() => {
    refetchProducts();
  }, [key]);

  const rootPaper = classNames({
    [classes.rootPaper]: true,
    [classes.squared]: classes.squared,
    [classes.outlined]: classes.outlined,
  });

  interface IPlacesNamesProps {
    product: IProduct;
  }
  const PlacesNames = (props: IPlacesNamesProps) => {
    const { product } = props;

    const [anchorEl, setAnchorEl] = useState<
      React.SyntheticEvent["currentTarget"] | null
    >(null);

    if ((product?.places?.length || 0) === 0) {
      return "Aucun lieu de stockage";
    }

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return [
      <Typography key="place-open-popover">
        <LinkUi
          className={classes.placeOpenPopover}
          onClick={(event: React.SyntheticEvent) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <Pluralize
            singular="lieu de stockage"
            plural="lieux de stockage"
            count={product.places.length}
          />
        </LinkUi>
      </Typography>,
      <Popover
        key="place-popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {product.places.map((place) => (
          <Typography key={place.id} className={classes.placePopover}>
            <LinkUi component={Link} to={`/places/${place.id}`}>
              {place.name}
            </LinkUi>
          </Typography>
        ))}
      </Popover>,
    ];
  };

  const handleCloseModalDeleteProduct = () => {
    if (!isPendingDeleteProduct) {
      setProductToDelete(undefined);
    }
  };

  return [
    productToDelete && (
      <ConfirmationModal
        key="delete-product-confirmation-modal"
        open
        loadingSuccessCallback={isPendingDeleteProduct}
        title="Suppression d'un produit."
        description={`Voulez-vous vraiment supprimer le produit "${productToDelete.name}" ?`}
        handleClose={handleCloseModalDeleteProduct}
        yesCallback={() => deleteProduct({ productId: productToDelete?.id })}
      />
    ),
    <Grid
      key="product-listing-header"
      className={classes.topContainer}
      container
      spacing={8}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item className={classes.fullWidthForXs}>
        <Search
          key="search-container"
          className={classes.search}
          placeholder="Rechercher le nom d'un produit"
          callback={(value) => setSearch(value)}
          disabled={isFetching || search ? false : products.length <= 0}
        />
      </Grid>
      <Grid item className={classes.fullWidthForXs}>
        <Button
          className={classes.fullWidthForXs}
          variant="contained"
          color="secondary"
          onClick={() => navigate("/new-product")}
        >
          Ajouter un produit
        </Button>
      </Grid>
    </Grid>,
    !isFetching ? (
      <Grid key="listing-container" container spacing={24}>
        {products && products.length > 0 ? (
          products.map((product: IProduct) => (
            <Grid item key={product.id} lg={4} md={6} xs={12}>
              <Paper className={rootPaper} elevation={0}>
                <LinkUi
                  to={`/products/${product.id}`}
                  component={Link}
                  underline="none"
                >
                  <div className={classes.linkGoToProductDetail}>
                    <div className={classes.imageWrapper}>
                      <ProductImage
                        rootStyle={classes.image}
                        productId={product.id}
                      />
                    </div>
                    <Typography className={classes.title} variant="h4">
                      {product.name}
                    </Typography>
                  </div>
                </LinkUi>
                <Divider />
                <Grid
                  className={classes.paperFooter}
                  container
                  justifyContent="space-between"
                >
                  <PlacesNames key="place-names" product={product} />
                  <DeleteIcon
                    className={classes.deleteIcon}
                    onClick={() => setProductToDelete(product)}
                    color="action"
                  />
                </Grid>
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid key="no-result" item container justifyContent="center" xs={12}>
            Aucun résultat.
          </Grid>
        )}
      </Grid>
    ) : (
      <div key="loader-container">
        <PageLoader />
      </div>
    ),
  ];
};

export default ProductsListing;
