import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";

interface TableComponentProps {
  Header?: React.FC;
  Footer?: React.FC;
  items: Array<Array<JSX.Element>>;
  columnsName: Array<string>;
}

const TableComponent = (props: TableComponentProps) => {
  const { Header = null, Footer = null, items, columnsName } = props;

  let keyForRow = 0;
  const rowKey = () => {
    keyForRow += 1;
    return keyForRow;
  };

  let keyForItem = 0;
  const itemKey = () => {
    keyForItem += 1;
    return keyForItem;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        {((Header && typeof Header === "function") ||
          (columnsName && columnsName.length > 0)) && (
          <TableHead>
            {Header && typeof Header === "function" && (
              <TableRow>
                <TableCell colSpan={columnsName.length || 1}>
                  <Header />
                </TableCell>
              </TableRow>
            )}
            {columnsName && columnsName.length > 0 && (
              <TableRow>
                {columnsName.map((columnName) => (
                  <TableCell key={columnName}>{columnName}</TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
        )}
        <TableBody>
          {items &&
            items.map((item: Array<JSX.Element>) => (
              <TableRow key={rowKey()}>
                {item.map((Item: JSX.Element) => (
                  <TableCell key={itemKey()}>{Item}</TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
        {Footer && typeof Footer === "function" && (
          <TableFooter>
            <TableRow>
              <TableCell>
                <Footer />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
