import axios from "axios";
import config from "../config";
import { IPlace } from "types/place.type";
import { TFilter, TJoin } from "types/query.type";

export const getPlaces = (join: TJoin, filter: TFilter) =>
  axios.get(`${config.API.HOST}/places`, {
    params: {
      join,
      filter,
    },
  });

export const postPlace = (place: FormData) =>
  axios.post(`${config.API.HOST}/places`, place);

export const patchPlace = (placeId: IPlace["id"], placeFormData: FormData) =>
  axios.patch(`${config.API.HOST}/places/${placeId}`, placeFormData);

export const getPlace = (placeId: IPlace["id"]) =>
  axios.get(`${config.API.HOST}/places/${placeId}`);

export const getPlacePicture = (placeId: IPlace["id"]) =>
  axios.get(`${config.API.HOST}/places/${placeId}/picture`, {
    responseType: "blob",
  });

export const deletePlace = (placeId: IPlace["id"]) =>
  axios.delete(`${config.API.HOST}/places/${placeId}`);

export const getPlacesHistory = (placeId: IPlace["id"]) =>
  axios.get(`${config.API.HOST}/actionshistory`, {
    params: {
      join: "product||true,place,warehouse",
      filter: [`placeId||eq||${placeId}`, "sourceType||eq||PLACE"],
      sort: "createdAt,ASC",
    },
  });
