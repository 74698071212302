import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdateWarehouse from "../../components/Warehouses/CreateOrUpdateWarehouse";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import SnackbarCloseIcon from "../../components/Snackbar/SnackbarCloseIcon";
import WarehousePlaces from "../../components/Warehouses/WarehousePlaces";
import ResourceHistory from "../../components/ResourceHistory/ResourceHistory";
import DeleteWarehousePlacesLinkedsModal from "../../components/Warehouses/DeleteWarehousePlacesLinkedsModal";
import {
  useDeleteWarehouse,
  useWarehouse,
  useWarehousesHistory,
} from "../../Hooks/warehouses.hook";
import { usePlaces } from "../../Hooks/places.hook";
import { IWarehouse } from "types/warehouse.type";
import { AxiosError, AxiosResponse } from "axios";

const WarehouseDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { warehouseId = "" } = useParams<{
    warehouseId: IWarehouse["id"];
  }>();
  const navigate = useNavigate();

  const [showConfirmDeleteWarehouse, setShowConfirmDeleteWarehouse] =
    useState(false);
  const [placesLinkedsDelete, setPlacesLinkedsDelete] = useState([]);

  const {
    warehouse,
    isFetching: isFetchingWarehouse,
    isError: isErrorWarehouse,
    error: errorWarehouse,
  } = useWarehouse({
    warehouseId,
  });

  const { mutate: deleteWarehouse, isPending: isPendingDeleteWarehouse } =
    useDeleteWarehouse({
      onSuccess: () => {
        enqueueSnackbar(`L'entrepôt "${warehouse.name}" a été supprimé.`, {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
        navigate("/warehouses");
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const warehouseHavePlacesLinkeds =
          data?.title?.error &&
          data?.title?.message &&
          data?.title?.productsLinkeds &&
          data?.title?.error === "WAREHOUSE_HAVE_PLACES_LINKEDS";
        if (warehouseHavePlacesLinkeds) {
          setPlacesLinkedsDelete(data.title.placesLinkeds);
        } else {
          enqueueSnackbar(
            `Une erreur est survenue lors de la suppression de l'entrepôt "${warehouse.name}".`,
            {
              variant: "error",
              action: (snackbarKey) => (
                <SnackbarCloseIcon snackbarKey={snackbarKey} />
              ),
            },
          );
        }
      },
    });

  const {
    warehousesHistory,
    isFetching: isFetchingWarehousesHistory,
    isError: isErrorWarehousesHistory,
  } = useWarehousesHistory({
    warehouseId,
  });

  const {
    places: placesLinkeds,
    isFetching: isFetchingPlacesLinkeds,
    isError: isErrorPlacesLinkeds,
  } = usePlaces({
    join: "warehouse",
    filter: `warehouse.id||eq||${warehouse.id}`,
  });

  useEffect(() => {
    if (isErrorWarehouse) {
      const warehouseNotFound =
        (errorWarehouse as AxiosError)?.response?.status === 404;

      enqueueSnackbar(
        warehouseNotFound
          ? `L'entrepôt "${warehouseId}" est introuvable.`
          : "Une erreur est survenue lors de la récupération de l'entrepôt.",
        {
          variant: warehouseNotFound ? "warning" : "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
      navigate("/warehouses");
    }
  }, [enqueueSnackbar, isErrorWarehouse, navigate, warehouseId]);

  useEffect(() => {
    if (isErrorWarehousesHistory) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération de l'historique de l'entrepôt.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [isErrorWarehousesHistory, enqueueSnackbar]);

  useEffect(() => {
    if (isErrorPlacesLinkeds) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des lieux de stockage liés à l'entrepôt.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [isErrorPlacesLinkeds, enqueueSnackbar, warehouse.name]);

  const handleChangeConfirmDeleteWarehouse = () => {
    if (!isPendingDeleteWarehouse) {
      setShowConfirmDeleteWarehouse(!showConfirmDeleteWarehouse);
    }
  };

  const hancleCloseDeleteWarehousePlacesLinkeds = () => {
    setShowConfirmDeleteWarehouse(false);
    setPlacesLinkedsDelete([]);
  };

  return (
    <MainLayout>
      {showConfirmDeleteWarehouse && placesLinkedsDelete.length > 0 && (
        <DeleteWarehousePlacesLinkedsModal
          placesLinkeds={placesLinkedsDelete}
          warehouse={warehouse}
          hancleClose={hancleCloseDeleteWarehousePlacesLinkeds}
        />
      )}
      {showConfirmDeleteWarehouse && (
        <ConfirmationModal
          key="delete-warehouse-confirmation-modal"
          open
          loadingSuccessCallback={isPendingDeleteWarehouse}
          title="Suppression d'un entrepôt."
          description={`Voulez-vous vraiment supprimer l'entrepôt "${warehouse.name}" ?`}
          handleClose={handleChangeConfirmDeleteWarehouse}
          yesCallback={() => deleteWarehouse({ warehouseId })}
        />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            onClick={handleChangeConfirmDeleteWarehouse}
            variant="contained"
            color="secondary"
            disabled={isFetchingWarehouse}
          >
            Supprimer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={6} xl={4}>
              <CreateOrUpdateWarehouse
                warehouseUpdate={warehouse}
                title={
                  warehouse && warehouse.name
                    ? `Mise à jour de "${warehouse.name}"`
                    : ""
                }
                loading={isFetchingWarehouse}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WarehousePlaces
            loading={isFetchingPlacesLinkeds}
            places={placesLinkeds}
          />
        </Grid>
        <Grid item xs={12}>
          <ResourceHistory
            history={warehousesHistory}
            loading={isFetchingWarehousesHistory}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default WarehouseDetail;
