import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "tss-react/mui";
import { useQueryClient } from "@tanstack/react-query";
import PageLoader from "../Loaders/PageLoader";
import ProductImage from "./ProductImage";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import { useCreateProduct, useEditProduct } from "../../Hooks/products.hook";
import { Theme } from "@mui/material";
import { IProduct } from "types/product.type";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(8),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  imageWrapper: {
    display: "flex",
    height: "250px",
  },
  image: {
    margin: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

interface ICreateOrUpdateProductProps {
  loading?: boolean;
  productUpdate?: IProduct;
  title: string;
}

const CreateOrUpdateProduct = (props: ICreateOrUpdateProductProps) => {
  const { loading = false, productUpdate = null, title } = props;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { classes } = useStyles();
  const initialValues = {
    productName: productUpdate && productUpdate.name ? productUpdate.name : "",
    productPicture: "",
    productHasOpeningDate:
      productUpdate && productUpdate.hasOpeningDate
        ? productUpdate.hasOpeningDate
        : false,
  };

  const { mutate: createProduct, isPending: isPendingCreateProduct } =
    useCreateProduct({
      onSuccess: ({ data }) => {
        enqueueSnackbar(`Le produit "${data.name}" a été ajouté.`, {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
        navigate(`/products/${data.id}`);
      },
      onError: ({ config }) => {
        const { data } = config as AxiosRequestConfig;

        enqueueSnackbar(
          `Une erreur est survenue lors de l'ajout du produit "${data.name}".`,
          {
            variant: "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
      },
    });

  const { mutate: editProduct, isPending: isPendingEditProduct } =
    useEditProduct({
      onSuccess: ({ data }) => {
        enqueueSnackbar(
          `Le produit "${productUpdate?.name}" a été mis à jour.`,
          {
            variant: "success",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );

        if (productUpdate?.pictureFileName !== data.pictureFileName) {
          queryClient.invalidateQueries({
            queryKey: ["product-picture", { productId: productUpdate?.id }],
            exact: true,
          });
        }

        queryClient.refetchQueries({
          queryKey: ["product", { productId: productUpdate?.id }],
          exact: true,
        });
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const productHaveOpening =
          data &&
          data.title &&
          data.title === "PRODUCT_PATCH_HAS_OPENING_DATE_HAVE_OPENING";

        let errorMessage = "";
        if (productHaveOpening) {
          errorMessage =
            "Il est impossible de modifier si le produit à une date d'ouverture car il y a au moins un produit ouvert lié au produit.";
        } else {
          errorMessage = `Une erreur est survenue lors de la mise à jour du produit "${productUpdate?.name || ""}".`;
        }

        enqueueSnackbar(errorMessage, {
          variant: productHaveOpening ? "warning" : "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
      },
    });

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          {productUpdate && (
            <div className={classes.imageWrapper}>
              <ProductImage
                rootStyle={classes.image}
                productId={productUpdate.id}
              />
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={({
              productName,
              productPicture,
              productHasOpeningDate,
            }) => {
              const formData = new FormData();
              formData.append("name", productName);
              if (productPicture) {
                formData.append("picture", productPicture);
              }
              formData.append(
                "hasOpeningDate",
                productHasOpeningDate.toString(),
              );

              if (productUpdate) {
                editProduct({ productId: productUpdate.id, product: formData });
              } else {
                createProduct({ product: formData });
              }
            }}
            validationSchema={yup.object().shape({
              productName: yup
                .string()
                .required("Le nom du produit est requis."),
              productPicture: yup
                .mixed<File>()
                .test(
                  "fileFormat",
                  "Le format de l'image n'est pas valide.",
                  (value) => value?.type?.startsWith("image/") || true,
                ),
              productHasOpeningDate: yup.bool(),
            })}
            render={({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  label="Nom du produit"
                  type="text"
                  name="productName"
                  autoFocus
                  value={values.productName}
                  onChange={handleChange}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.productName &&
                    touched.productName &&
                    errors.productName
                  }
                  error={!!errors.productName && touched.productName}
                />

                <TextField
                  label={productUpdate ? "Mettre à jour l'image" : "Image"}
                  inputProps={{ accept: "image/*" }}
                  InputLabelProps={{ shrink: true }}
                  type="file"
                  name="productPicture"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { files } = event.currentTarget;

                    setFieldValue(
                      "productPicture",
                      files && files[0] ? files[0] : undefined,
                    );
                  }}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.productPicture &&
                    touched.productPicture &&
                    errors.productPicture
                  }
                  error={!!errors.productPicture && !!touched.productPicture}
                />

                <FormControlLabel
                  label="Produit avec une date d'ouverture ?"
                  className={classes.textField}
                  control={
                    <Checkbox
                      checked={values.productHasOpeningDate}
                      onChange={handleChange}
                      value="productHasOpeningDate"
                      name="productHasOpeningDate"
                    />
                  }
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isPendingEditProduct || isPendingCreateProduct}
                >
                  {(isPendingEditProduct || isPendingCreateProduct) && (
                    <CircularProgress color="inherit" size={14} />
                  )}
                  {(!isPendingEditProduct || !isPendingCreateProduct) &&
                    (productUpdate ? "Mettre à jour" : "Ajouter")}
                </Button>
              </form>
            )}
          />
        </>
      )}
    </Paper>
  );
};

export default CreateOrUpdateProduct;
