import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, Button } from "@mui/material";
import MainLayout from "../../layouts/mainLayout/MainLayout";
import CreateOrUpdatePlace from "../../components/Places/CreateOrUpdatePlace";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import SnackbarCloseIcon from "../../components/Snackbar/SnackbarCloseIcon";
import PlaceProducts from "../../components/Places/PlaceProducts";
import ResourceHistory from "../../components/ResourceHistory/ResourceHistory";
import PlaceLinkedToProductsModal from "../../components/Places/PlaceLinkedToProductsModal";
import { useProducts } from "../../Hooks/products.hook";
import { useWarehouses } from "../../Hooks/warehouses.hook";
import {
  useDeletePlace,
  usePlace,
  usePlacesHistory,
} from "../../Hooks/places.hook";
import { AxiosError, AxiosResponse } from "axios";
import { IPlace } from "types/place.type";

const PlaceDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { placeId = "" } = useParams<{
    placeId: IPlace["id"];
  }>();
  const navigate = useNavigate();

  const [showConfirmDeletePlace, setShowConfirmDeletePlace] = useState(false);
  const [productsLinkeds, setProductsLinkeds] = useState([]);

  const {
    products,
    isFetching: isFetchingProducts,
    isError: isErrorProducts,
  } = useProducts({
    join: "places",
    filter: `places.id||eq||${placeId}`,
  });

  const {
    warehouses,
    isFetching: isFetchingWarehouses,
    isError: isErrorWarehouses,
  } = useWarehouses({});

  const {
    place,
    isFetching: isFetchingPlace,
    isError: isErrorPlace,
    error: errorPlace,
  } = usePlace({
    placeId,
  });

  const { mutate: deletePlace, isPending: isPendingDeletePlace } =
    useDeletePlace({
      onSuccess: () => {
        enqueueSnackbar(`Le lieu de stockage "${place.name}" a été supprimé.`, {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
        navigate("/places");
      },
      onError: ({ response }) => {
        const { data } = response as AxiosResponse;

        const placeHaveProductsLinkeds =
          data &&
          data.title &&
          data.title.error &&
          data.title.message &&
          data.title.productsLinkeds &&
          data.title.error === "PLACE_HAVE_PRODUCTS_LINKEDS";
        if (placeHaveProductsLinkeds) {
          setShowConfirmDeletePlace(false);
          setProductsLinkeds(data.title.productsLinkeds);
        } else {
          enqueueSnackbar(
            `Une erreur est survenue lors de la suppression du lieu de stockage "${place.name}".`,
            {
              variant: "error",
              action: (snackbarKey) => (
                <SnackbarCloseIcon snackbarKey={snackbarKey} />
              ),
            },
          );
        }
      },
    });

  const {
    placesHistory,
    isFetching: isFetchingPlacesHistory,
    isError: isErrorPlacesHistory,
    refetch: refetchPlacesHistory,
  } = usePlacesHistory({
    placeId,
  });

  useEffect(() => {
    if (isErrorProducts) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des produits liés au lieu de stockage.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [enqueueSnackbar, isErrorProducts]);

  useEffect(() => {
    if (isErrorWarehouses) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des entrepôts. Impossible de modifier le lieu de stockage.",
        {
          variant: "error",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );
      navigate("/places");
    }
  }, [enqueueSnackbar, isErrorWarehouses, navigate]);

  useEffect(() => {
    if (isErrorPlace) {
      const placeNotFound =
        (errorPlace as AxiosError)?.response?.status === 404;

      enqueueSnackbar(
        placeNotFound
          ? `Le lieu de stockage "${placeId}" est introuvable.`
          : "Une erreur est survenue lors de la récupération du lieu de stockage.",
        {
          variant: placeNotFound ? "warning" : "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
      navigate("/places");
    }
  }, [enqueueSnackbar, isErrorPlace, navigate, placeId]);

  useEffect(() => {
    if (isErrorPlacesHistory) {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération de l'historique du lieu de stockage.",
        {
          variant: "error",
          action: (key) => <SnackbarCloseIcon snackbarKey={key} />,
        },
      );
    }
  }, [isErrorPlacesHistory, enqueueSnackbar]);

  const handleChangeConfirmDeletePlace = () => {
    if (!isPendingDeletePlace) {
      setShowConfirmDeletePlace(!showConfirmDeletePlace);
    }
  };

  return (
    <MainLayout>
      {productsLinkeds.length > 0 && (
        <PlaceLinkedToProductsModal
          title={`Il n'est pas possible de supprimer le lieu de stockage "${place.name}".`}
          productsLinkeds={productsLinkeds}
          place={place}
          handleClose={() => setProductsLinkeds([])}
          transitionDurationEnter={0}
        />
      )}
      {showConfirmDeletePlace && (
        <ConfirmationModal
          key="delete-place-confirmation-modal"
          open
          loadingSuccessCallback={isPendingDeletePlace}
          title="Suppression d&#39;un lieu de stockage."
          description={`Voulez-vous vraiment supprimer le lieu de stockage "${place.name}" ?`}
          handleClose={handleChangeConfirmDeletePlace}
          yesCallback={() => deletePlace({ placeId })}
        />
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            onClick={handleChangeConfirmDeletePlace}
            variant="contained"
            color="secondary"
            disabled={isFetchingPlace || isFetchingWarehouses}
          >
            Supprimer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={6} xl={4}>
              <CreateOrUpdatePlace
                place={place}
                title={
                  place && place.name ? `Mise à jour de "${place.name}"` : ""
                }
                callback={() => refetchPlacesHistory()}
                loading={isFetchingPlace || isFetchingWarehouses}
                warehouses={warehouses}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PlaceProducts loading={isFetchingProducts} products={products} />
        </Grid>
        <Grid item xs={12}>
          <ResourceHistory
            history={placesHistory}
            loading={isFetchingPlacesHistory}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default PlaceDetail;
