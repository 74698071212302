import { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/lab/Autocomplete";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { makeStyles } from "tss-react/mui";
import { useQueryClient } from "@tanstack/react-query";
import PageLoader from "../Loaders/PageLoader";
import SnackbarCloseIcon from "../Snackbar/SnackbarCloseIcon";
import PlaceImage from "./PlaceImage";
import PlaceLinkedToProductsModal from "./PlaceLinkedToProductsModal";
import { useCreatePlace, useEditPlace } from "../../Hooks/places.hook";
import { IWarehouse } from "types/warehouse.type";
import { IPlace } from "types/place.type";
import { IProduct } from "types/product.type";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const useStyles = makeStyles()((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3, 3),
  },
  submit: {
    float: "right",
    marginTop: theme.spacing(8),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  imageWrapper: {
    display: "flex",
    height: "250px",
  },
  image: {
    margin: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
  },
  popupIndicator: {
    display: "none",
  },
  noWarehouse: {
    margin: "auto",
    textAlign: "center",
  },
}));

interface ICreateOrUpdatePlaceProps {
  loading?: boolean;
  place?: IPlace | null;
  callback?: () => void;
  title: string;
  warehouses: Array<IWarehouse>;
}

const CreateOrUpdatePlace = (props: ICreateOrUpdatePlaceProps) => {
  const {
    loading = false,
    place = null,
    callback = null,
    title,
    warehouses,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { classes } = useStyles();

  const [productsLinkeds, setProductsLinkeds] = useState<Array<IProduct>>([]);

  const { mutate: createPlace, isPending: isPendingCreatePlace } =
    useCreatePlace({
      onSuccess: ({ data }) => {
        enqueueSnackbar(`Le lieu de stockage "${data.name}" a été ajouté.`, {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        });
        navigate(`/places/${data.id}`);
      },
      onError: ({ config }) => {
        const { data } = config as AxiosRequestConfig;

        enqueueSnackbar(
          `Une erreur est survenue lors de l'ajout du lieu de stockage "${data.get("name")}".`,
          {
            variant: "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
      },
    });

  const { mutate: editPlace, isPending: isPendingEditPlace } = useEditPlace({
    onSuccess: ({ data }) => {
      enqueueSnackbar(
        `Le lieu de stockage "${place?.name || ""}" a été mis à jour.`,
        {
          variant: "success",
          action: (snackbarKey) => (
            <SnackbarCloseIcon snackbarKey={snackbarKey} />
          ),
        },
      );

      if (place?.pictureFileName !== data.pictureFileName) {
        queryClient.invalidateQueries({
          queryKey: ["place-picture", { placeId: place?.id }],
          exact: true,
        });
      }

      queryClient.refetchQueries({
        queryKey: ["place", { placeId: place?.id }],
        exact: true,
      });

      if (callback) {
        callback();
      }
    },
    onError: ({ response }) => {
      const { data } = response as AxiosResponse;

      const placeHaveProductsLinkeds =
        data &&
        data.title &&
        data.title.error &&
        data.title.message &&
        data.title.productsLinkeds &&
        data.title.error === "PLACE_HAVE_PRODUCTS_LINKEDS";

      if (placeHaveProductsLinkeds) {
        setProductsLinkeds(data?.title?.productsLinkeds);
      } else {
        enqueueSnackbar(
          `Une erreur est survenue lors de la mise à jour du lieu de stockage "${place?.name || ""}".`,
          {
            variant: "error",
            action: (snackbarKey) => (
              <SnackbarCloseIcon snackbarKey={snackbarKey} />
            ),
          },
        );
      }
    },
  });

  interface IInitialValues {
    placeName: string;
    warehouseId: string;
    placePicture?: string;
  }

  const initialValues: IInitialValues = {
    placeName: place?.name || "",
    placePicture: undefined,
    warehouseId: place?.warehouseId?.toString() || "",
  };

  if (!loading && (!warehouses || warehouses.length === 0)) {
    return (
      <Paper className={classes.paper}>
        <div className={classes.noWarehouse}>
          <div>Impossible de récupérer les entrepôts.</div>
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/places")}
            >
              Retourner au listing des lieux de stockage.
            </Button>
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {productsLinkeds.length > 0 && (
            <PlaceLinkedToProductsModal
              title={`Il n'est pas possible de changer d'entrepôt le lieu de stockage "${place?.name || ""}".`}
              productsLinkeds={productsLinkeds}
              place={place || undefined}
              handleClose={() => setProductsLinkeds([])}
            />
          )}
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
          {place && (
            <div className={classes.imageWrapper}>
              <PlaceImage rootStyle={classes.image} placeId={place.id} />
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={({
              placeName,
              warehouseId,
              placePicture,
            }: IInitialValues) => {
              const formData = new FormData();
              formData.append("name", placeName);
              formData.append("warehouseId", warehouseId);
              if (placePicture) {
                formData.append("picture", placePicture);
              }

              if (!place) {
                createPlace({ place: formData });
              } else {
                editPlace({
                  placeId: place.id,
                  place: formData,
                });
              }
            }}
            validationSchema={yup.object().shape({
              placeName: yup
                .string()
                .required(`Le nom du lieu de stockage est requis.`),
              placePicture: yup
                .mixed<File>()
                .test(
                  "fileFormat",
                  "Le format de l'image n'est pas valide.",
                  (value) => value?.type?.startsWith("image/") || true,
                ),
              warehouseId: yup
                .string()
                .required(
                  `L'entrepôt dans lequel se trouve le lieu de stockage est requis.`,
                )
                .test(
                  "warehouseId",
                  "L'entrepôt dans lequel se trouve le lieu de stockage n'est pas valide.",
                  (value) =>
                    !!warehouses.find((warehouse) => warehouse?.id === value),
                ),
            })}
            render={({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <TextField
                  label="Nom du lieu de stockage"
                  type="text"
                  name="placeName"
                  autoFocus
                  value={values.placeName}
                  onChange={handleChange}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.placeName && touched.placeName && errors.placeName
                  }
                  error={!!errors?.placeName && touched?.placeName}
                />

                <Autocomplete
                  onChange={(_, value) => {
                    setFieldValue("warehouseId", value ? value.id : "");
                  }}
                  value={
                    warehouses[
                      warehouses.findIndex(
                        (warehouse) =>
                          warehouse?.id.toString() === values.warehouseId,
                      )
                    ]
                  }
                  options={warehouses}
                  getOptionLabel={(option) => option.name}
                  disableClearable
                  classes={{ popupIndicator: classes.popupIndicator }}
                  noOptionsText="Aucun entrepôt"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Entrepôt"
                      fullWidth
                      className={classes.textField}
                      helperText={
                        errors.warehouseId &&
                        touched.warehouseId &&
                        errors.warehouseId
                      }
                      error={!!errors?.warehouseId && touched?.warehouseId}
                    />
                  )}
                />

                <TextField
                  label={place ? "Mettre à jour l'image" : "Image"}
                  inputProps={{ accept: "image/*" }}
                  InputLabelProps={{ shrink: true }}
                  type="file"
                  name="placePicture"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { files } = event.currentTarget;

                    setFieldValue(
                      "placePicture",
                      files && files[0] ? files[0] : undefined,
                    );
                  }}
                  fullWidth
                  className={classes.textField}
                  helperText={
                    errors.placePicture &&
                    touched.placePicture &&
                    errors.placePicture
                  }
                  error={!!errors?.placePicture && !!touched.placePicture}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isPendingEditPlace || isPendingCreatePlace}
                >
                  {(isPendingEditPlace || isPendingCreatePlace) && (
                    <CircularProgress color="inherit" size={14} />
                  )}
                  {(!isPendingEditPlace || !isPendingCreatePlace) &&
                    (place ? "Mettre à jour" : "Ajouter")}
                </Button>
              </form>
            )}
          />
        </>
      )}
    </Paper>
  );
};

export default CreateOrUpdatePlace;
